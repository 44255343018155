.banner__carousel{
	.carousel-indicators{
		@include media-breakpoint-up (lg) {
			bottom: 30px;
		}
		li{
			border: none;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			opacity: 1;
			background-color: #fff;
			margin: 0px 2px;
			border-radius: 6px;
			transition: all 0.3s linear;

			&.active{
				width: 24px;
			}
		}
	}
}
.quem__somos{
	padding: 90px 0px 100px 0px;

	.row{
		align-items: center;
	}

	@include media-breakpoint-down (md) {
		padding: 35px 0px 40px 0px;

		.segura__btn{
			justify-content: center;
			display: flex;
			padding-top: 15px;
		}

		.col-lg-6{
			&:nth-child(1){
				order: 2;
			}
			&:nth-child(2){
				order: 1;
				margin-bottom: 50px;
			}
		}
	}
}
.quem__img{
	text-align: center;
}
.titulo__secao{
	font-family: poppinsbold;
	font-size: 16px;
	line-height: 20px;
	color: #00b4ff;
	padding-bottom: 10px;
	position: relative;
	margin-bottom: 20px;

	&:after{
		width: 45px;
		left: 0px;
		bottom: 0px;
		height:3px;
		content: '';
		position: absolute;
		background-color: currentColor;
		border-radius: 2px;
	}
}
.sub__secao{
	font-family: poppinssemibold;
	color: #0084bc;
	font-size: 35px;
	line-height: 39px;
	margin-bottom: 20px;

	@include media-breakpoint-down (md) {
		font-size: 28px;
		line-height: 32px;
	}
}
.segura__btn{
	padding-top: 30px;
}
.btn__conteudo{
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 255px;
	min-height: 64px;
	border-radius: 32px;
	border: none;
	font-family: poppinsbold;
	font-size: 16px;
	line-height: 18px;
	text-align: center;
	background-color: #0084bc;
	color: #fff;
	transition: all 0.3s linear;

	@include media-breakpoint-down (sm) {
		min-width: 200px;
		min-height: 50px;
		border-radius: 25px;
	}

	&:hover{
		background-color: #00b4ff;
		color: #fff;
		text-decoration: none;
	}
}
.segura__btn{
	&.lg__only{
		@include media-breakpoint-down (md) {
			display: none;
		}
	}
	&.mbl__only{
		@include media-breakpoint-up (lg) {
			display: none;
		}
	}
}
.col-lg-8{
	.segura__btn{
		display: flex;
		justify-content:center;
		padding-top: 40px;
	}
}
.servicos{
	padding: 80px 0px 55px 0px;
	background-color: #0084bc;
	color: #fff;
	overflow: hidden;

	.segura__btn{
		position: relative;
		z-index: 30;

		.btn__conteudo{
			min-width: 195px;
		}
	}

	.servico__tab__conteudo{
		position: relative;
		z-index: 50;
	}

	.conteudo__gerenciavel{
		color: #fff;
		margin-bottom: 35px;
	}

	.header__quem{
		margin-bottom: 10px;
		.sub__secao{
			color: #fff;
		}
	}

	@include media-breakpoint-down (md) {
		padding: 40px 0px 25px 0px;
	}
}
.servicos__tabs{
	position: relative;
	z-index: 10;

	&:after{
		width: 300vw;
		left: -100vw;
		content: '';
		position: absolute;
		z-index: -1;
		background-color: #fff;
		top: 38px;
		height: 1000px;
	}

	.nav-tabs{
		border: none;
		padding-bottom: 20px;
		border-bottom: 3px solid #e4e4e4;
		margin-bottom: 50px;
		li{
			border: none;
			margin: unset;
			&:nth-child(3),
			&:nth-child(4){
				@include media-breakpoint-up (lg){
					a{
						.servico__tab__titulo{
							right: 30px;
							left: unset;
						}
					}
				}
			}
			a{
				position: relative;
				border: none;
				background-color: transparent;
				padding: 0px;
				border: none;
				border-radius: unset;

				&:after{
					width: 10px;
					height: 10px;
					background-color: #0084bc;
					border-radius: 50%;
					position: absolute;
					top: calc(100% + 15px);
					left: calc(50% - 5px);
					content: '';
					opacity: 0;
					transition:all 0.3s linear;
				}

				&.active{
					.servico__tab__titulo{
						opacity: 1;
					}
					&:after{
						opacity: 1;
					}
				}

				@include media-breakpoint-up (lg) {
					.servico__tab__titulo{
						position: absolute;
						opacity: 0;
						font-family: poppinssemibold;
						font-size: 18px;
						line-height: 20px;
						color: #00b3ff;
						top: calc(100% + 40px);
						left: 30px;
						transition: all 0.3s linear;
					}
				}
			}
		}
	}
}
.servico__tab__icone{
	width: 76px;
	height: 76px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background-color: #fff;
	border: 5px solid #fff;

	.segura__icone{
		background-color: #0084bc;
		mask-position: center;
		mask-size: auto;
		mask-repeat: no-repeat;
		width: 50px;
		height: 50px;
	}
}
.servicos__tabs{
	.nav-tabs{
		column-gap: 15px;
	}
	.nav-item{
		a{
			&.active{
				background-color: transparent !important;
				.servico__tab__icone{
					background-color: #00b3ff;

					.segura__icone{
						background-color: #fff;
					}
				}
			}
		}
	}
}

.servico__card__home{
	display: block;
	width: 100%;
	max-width: 730px;
	margin-left: auto;
	margin-right: auto;
	position: relative;

	&:after{
		width: 375px;
		height: 250px;
		position: absolute;
		background-color: #00b3ff;
		right: -25px;
		bottom: -25px;
		content: '';
		z-index: -1;
	}

	&:hover{
		text-decoration: none;
		.servico__card__btn{
			text-decoration: underline;
		}
	}

	.servico__card__txt{
		position: absolute;
		right: 15px;
		top: 0px;
		background-color: rgba(#000, 0.7);
		color: #fff;
		padding: 25px 20px;
		text-align: center;
		max-width: 250px;

		.servico__card__titulo{
			font-family: poppinssemibold;
			font-size: 18px;
			line-height: 22px;
			margin-bottom: 15px;
		}

		.servico__card__btn{
			text-align: center;
			font-family: poppinssemibold;
			font-size: 14px;
			line-height: 16px;
		}

		.servico__card__desc{
			font-size: 14px;
			line-height: 18px;
			margin-bottom: 20px;
			.line__clamp{
				--linhas: 5;
			}
		}

		&:after{
			width: 4px;
			height: 30px;
			background-color: #fff;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			top: -15px;
			content: '';
		}
	}
}
.produtos{
	padding: 45px 0px 85px 0px;

	.segura__btn{
		display: flex;
		justify-content:center;
		padding-top: 40px;

		.btn__conteudo{
			min-width: 195px;
		}
	}

	@include media-breakpoint-down (md) {
		padding: 30px 0px 40px 0px;
	}

	.header__quem{
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items:center;
		max-width: 560px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 35px;
	}
}
.produto__card,
.port__card{
	display: block;
	width: 100%;
	max-width: 250px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	position: relative;
	background-color: transparent;
	padding: 0px;
	border: none;

	&:hover{
		text-decoration: none;

		.produto__card__img,
		.port__img{
			background-color: #000;
			img{
				opacity: 0.7;
			}
			&:after{
				opacity: 1;
			}
		}
	}

	.produto__card__img,
	.port__img{
		position: relative;
		background-color: #fff;
		margin-bottom: 16px;


		img{
			transition: all 0.3s linear;
		}

		&:after{
			width: 56px;
			height: 56px;
			display: flex;
			align-items:center;
			justify-content: center;
			border-radius: 50%;
			background-color: #0084bc;
			color: #fff;
			content: '+';
			font-size: 30px;
			line-height: 34px;
			font-family: poppinssemibold;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			opacity: 0;
			transition: all 0.3s linear;
		}
	}
	.produto__card__titulo,
	.port__titulo{
		font-family: poppinssemibold;
		font-size: 16px;
		line-height: 20px;
		color: #0084bc;
	}
}
.interna__conteudo{
	.port__card{
		.port__img{
			border-radius: 10px;

			img{
				border-radius: 10px;
			}
		}
		img{
			box-shadow: 0px 0px 10px rgba(#000, 0.5);
		}
	}
}
.produtos__owl.owl-carousel{
	button.owl-prev,
	button.owl-next{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 55px;
		height: 55px;
		border: 2px solid #0084bc;
		color: #0084bc;
		border-radius: 50%;
		background-color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		transition: all 0.3s linear;

		&:hover {
			background-color: #0084bc;
			color: #fff;
			text-decoration: none;
		}
	}
	button.owl-prev{
		left: -15px;

		@media (min-width: 1300px) {
			left: -65px;
		}
	}
	button.owl-next{
		right: -15px;

		@media (min-width: 1300px) {
			right: -65px;
		}
	}
}
.clientes{
	position: relative;
	padding-top: 75px;
	color: #fff;
	background-color: #0084bc;
	padding-bottom: 120px;


	&:after{
		height: 200px;
		position: absolute;
		bottom: 0px;
		width: 100%;
		content: '';
		left: 50%;
		transform: translateX(-50%);
		background-color: #fff;
	}

	@include media-breakpoint-down (md) {
		padding-top: 35px;
		padding-bottom: 50px;

		&:after{
			height: 130px;
		}
	}

	.cli__header{
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items:center;
		max-width: 480px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 75px;

		@include media-breakpoint-down (sm) {
			margin-bottom: 35px;
		}

		.sub__secao{
			color: #fff;
			margin-bottom: 0px;
		}
	}
}
.cliente__card{
	display: block;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	max-width: 230px;
	box-shadow: 0px 0px 10px rgba(#000, 0.3);
}
.clientes__owl{
	.owl-item{
		padding: 10px;
	}
}
@include media-breakpoint-down (md) {
	.servico__tab__titulo{
		display: none !important;
	}
	.servicos__tabs{
		.nav-tabs{
			justify-content: center;
		}
	}
}
.port__grid{
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	column-gap: 35px;
	row-gap: 50px;

	@include media-breakpoint-down (md) {
		grid-template-columns: repeat(3, 1fr);
		column-gap: 20px;
		row-gap: 30px;
	}
	@include media-breakpoint-down (sm) {
		grid-template-columns: repeat(2, 1fr);
		column-gap: 20px;
		row-gap: 30px;
	}
	@include media-breakpoint-down (xs) {
		grid-template-columns: 1fr;
		column-gap: 20px;
		row-gap: 30px;
	}
}
.modal__custom{
	.modal-dialog{
		max-width: 650px;
		width: 100%;
	}
	.modal-body{
		padding: 0px 20px;
	}
	.modal__logo{
		text-align: center;
		margin-bottom: 35px;
	}
	.modal__titulo{
		font-family: poppinssemibold;
		font-size: 35px;
		line-height: 38px;
		text-align: center;
		max-width: 520px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		color: #0084bc;
		margin-bottom: 30px;
	}
	.modal__desc{
		max-width: 470px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		font-family: poppinslight;
		font-size: 16px;
		line-height: 20px;
		color: #282828;
	}
}
.btn__center__modal{
	display: flex;
	justify-content: center;
	padding-top: 25px;

	.btn__conteudo{
		min-width: 180px;
	}
}
.modal-interna{
	padding: 40px 0px;
	text-align: center;
	.modal__logo{
		text-align: center;
		margin-bottom: 35px;
	}
	.modal__titulo{
		font-family: poppinssemibold;
		font-size: 35px;
		line-height: 38px;
		text-align: center;
		max-width: 520px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		color: #0084bc;
		margin-bottom: 30px;
	}
	.modal__desc{
		max-width: 470px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		font-family: poppinslight;
		font-size: 16px;
		line-height: 20px;
		color: #282828;
	}
}
