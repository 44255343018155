.rodape{
	.rodape__cima{
		position: relative;

		.embed-responsive{
			padding-bottom: 40%;

			@include media-breakpoint-down (lg) {
				padding-bottom: 52%;
			}
		}

		.rodape__conteudo{
			@include media-breakpoint-down (md) {
				background-color: #0077b6;
			}
			.container{
				padding: 70px 15px;
				background-color: #0077b6;
				color: #fff;

				display: flex;
				align-items: flex-start;
				justify-content:center;
				column-gap: 35px;

				@include media-breakpoint-down (md) {
					flex-direction: column;
					row-gap: 5px;
					text-align: center;
					align-items: center;
					row-gap: 25px;
					padding: 35px 15px;
				}

				@include media-breakpoint-up (lg) {
					position: absolute;
					bottom: 0px;
					left: 50%;
					transform: translateX(-50%);
				}

				.col__1{
					max-width: 316px;
					width: 100%;
				}
				.col__2{
					max-width: 316px;
					width: 100%;
				}
				.col__3{
					max-width: 150px;
					width: 100%;
				}
			}
		}
	}
}
.logo__rodape{
	margin-bottom: 20px;
}
.rodape__desc{
	max-width: 270px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	text-align: justify;

	@include media-breakpoint-down (md){
		text-align: center;
		max-width: 450px;
	}
}
.rodape__titulo{
	font-family: poppinssemibold;
	font-size: 18px;
	line-height: 22px;
	color: #00b4d8;
	margin-bottom: 10px;
}
.rodape__contato__icone{
	width: 25px;
	height: 25px;
	min-width: 25px;
	border-radius: 50%;
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 5px;
}
.rodape__contato{
	display: flex;
	align-items: center;
	margin-bottom: 10px;

	@include media-breakpoint-down (md) {
		flex-direction: column;
		row-gap: 5px;
		margin-bottom: 15px;

		.rodape__contato__icone{
			margin-right: 0px;
		}
	}
}
.endereco__rodape{
	margin-bottom: 10px;
	span{
		font-family: poppinsbold;
	}
}
.sociais__rodape{
	display: flex;
	align-items: center;
	column-gap: 5px;

	@include media-breakpoint-down (md) {
		justify-content: center;
		margin-top: 15px;
	}

	a{
		width: 25px;
		height: 25px;
		min-width: 25px;
		border-radius: 50%;
		background-color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #0077b6;
		transition: all 0.3s linear;

		&:hover{
			background-color: #00b4d8;
			color: #fff;
			text-decoration: none;
		}
	}
}
.rodape__menu{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
	font-size: 14px;
	line-height: 16px;
	font-family: poppinslight;

	li{
		margin-bottom: 10px;
	}

	a{
		&:hover{
			font-family: poppinsbold;
			text-decoration: underline;
		}
	}
}
.rodape__creditos{
	padding: 12px 0px;
	background-color: #00b4d8;
	color: #fff;
	text-align: center;
	font-size: 12px;
	line-height: 14px;

	.container{
		display: flex;
		align-items: center;
		justify-content: center;

		@include media-breakpoint-down (md) {
			flex-direction: column;
			row-gap: 5px;
		}
	}
}
.gv8__box{
	display: flex;
	align-items: center;
	column-gap: 5px;
}
