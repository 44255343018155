.atendimento__item{
	padding: 12px 20px;
	font-size: 13px;
	line-height: 15px;
	color: #3f3f3f;
	.atend__item__titulo{
		font-family: poppinssemibold;
		margin-bottom: 3px;
	}
}
.topo__cima{
	background-color: #0187bd;
	color: #fff;
}
.main__menu{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
}
.atendimento__toggler{
	background-color: transparent;
	width: 45px;
	height: 45px;
	min-width: 45px;
	border: 1px solid #d3d3d3;
	color: #d3d3d3;
	background-color: #fff;
	transition: all 0.3s linear;
	border-radius: 50%;

	&[aria-expanded="true"]{
		background-color: #0187bd;
		color: #fff;
		border-color: #0187bd;
	}
}
.bg__menu{
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0px;
	top: 0px;
	background-color: rgba(#000000, 0.6);
	animation: fadeIn 0.5s linear;
	z-index: 1050;

	&.hide{
		animation: fadeOut 0.5s linear;
	}
}
.pesquisa__expanded{
    position: fixed;
    top: -150px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1080;
    max-width: 900px;
    width: 90%;
    transition: all 0.5s linear;

    &.shown{
        top: 48vh;
    }
}
.pesquisa__form{
    display: flex;
    overflow: hidden;
    background-color: transparent;
    align-items: center;
	padding: 3px;
	border-radius: 23px;
	border: 1px solid #d3d3d3;
	background-color: #fff;


    .pesquisa__btn{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #0187bd;
        min-width: 40px;
        width: 40px;
        height: 40px;
        border: none;
		border-radius: 50%;
		position: relative;
		color: #fff;
    }
    .pesquisa__input{
        display: block;
        width: 100%;
        font-family: 'poppinsregular';
        font-size: 12px;
        line-height: 14px;
        padding: 13px 20px 13px 20px;
        border: none;
        background-color: transparent;
        color: #000;
        border: none;
        border-radius: 20px;
		min-height: 40px;

        &::placeholder{
            color: #d3d3d3;
        }

        &:focus{
            outline: none;
        }
    }
    label{
        display: block;
        width: 100%;
        margin-bottom: 0px;
    }
}
.atend__titulo{
	padding: 12px 18px;
	background-color: #0187bd;
	color: #fff;
	font-family: poppinssemibold;
	font-size: 13px;
	line-height: 15px;
}
@include media-breakpoint-up (lg) {
	.segura__atendimento{
		display: flex;
		align-items: center;
		column-gap: 5px;
	}
	.topo__cima{
		.main__menu{
			display: flex;
			align-items: center;
			justify-content: center;

			a{
				display: block;
				padding: 15px 10px;
				font-family: poppinslight;
				font-size: 14px;
				line-height: 16px;

				&:hover{
					text-decoration: underline;
					font-family: poppinsbold;
				}
			}

			.menu__item{
				&.menu__item--active{
					.menu__link{
						background-color: #24b1ed;
						text-decoration: none;

						&:hover{
							text-decoration: none;
						}
					}
				}
			}
		}
	}
	.topo__baixo{
		padding: 12px 0px;

		.container{
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
	.baixo__right{
		max-width: 622px;
		width: 100%;
		display: flex;
		align-items: center;
		column-gap: 35px;
		position: relative;



		.main__pesquisa{
			width: 100%;
			max-width: 390px;
			position: relative;

			&:after{
				height:100%;
				width: 1px;
				content: '';
				position: absolute;
				right: -17px;
				top: 0px;
				background-color: #d3d3d3;
			}
		}
	}
	.topo__mobile{
		display: none;
	}
	.atendimento__toggler{
		position: relative;
		&[aria-expanded="true"] {
			&:after{
				display: block;
			}
		}

		&:after{
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-bottom: 12px solid #0187bd;
			content: '';
			position: absolute;
			left: 11px !important;
			bottom: -18px;
			display: none;
			z-index: 100;
			filter: drop-shadow(0px -3px 3px rgba(#000, 0.2));
		}
	}
	.atendimento__menu{
		min-width: 220px;
        max-width: 100%;
        left: unset !important;
		right: 0px !important;
        border-radius: 0px;
        border: none;
		transform: unset !important;
		top: calc(100% + 15px) !important;
		border-radius: 10px;
		overflow: hidden;
		padding: 0px;
	}
	.main__logo__mbl{
		display: none;
	}
}
@include media-breakpoint-down (md) {
	.main__logo{
		display: none;
	}
	.atendimento__menu{
		float: none !important;
		transform: unset !important;
		position: relative;
		display: block !important;
		padding: 0px;
		margin: 0px;
		border: none !important;

		.atendimento__btn{
			display: flex;
			justify-content:center;
		}
	}
	.atend__titulo{
		background-color: #3f3f3f;
		color: #fff;
	}
	.main__logo__mbl{
		padding: 10px;
		background-color: #fff;
	}
	.main__menu{
		a{
			font-family: poppinssemibold;
			display: block;
			padding: 10px;
		}
	}
	.menu__item--active{
		a{
			background-color: #24b1ed;
		}
	}
	.topo__mobile{
		padding: 8px;
		.mbl__logo{
			max-width: 200px;
		}
		.container{
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		.mbl__toggler{
			background-color: transparent;
			border: none;
			font-size: 20px;
			line-height: 20px;
			color: #0187bd;
		}
	}
	.topo__main{
		position: fixed;
		top: 0px;
		left: -250px;
		width: 250px;
		z-index: 1080;
		height: 100%;
		overflow-y: auto;
		background-color: #fff;
		transition: all 0.5s linear;
		flex-direction: column;
		display: flex;

		.atendimento__toggler{
			display: none;
		}

		.main__pesquisa{
			display: none;
		}

		.container{
			padding: 0px;
		}

		&.shown{
			left: 0px;
		}
	}
}
