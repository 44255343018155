// bg-position(x,y) width height
$icons: (
	'aviao': -46px -111px 13px 12px,
	'casa': -69px -111px 12px 10px,
	'clip': 0px 0px 50px 50px,
	'email': -91px -111px 13px 9px,
	'email2': -114px -111px 11px 8px,
	'fone': 0px -111px 13px 13px,
	'html': -111px 0px 32px 32px,
	'internaemail': -60px 0px 41px 41px,
	'internahome': 0px -60px 41px 41px,
	'internatel': -51px -60px 41px 40px,
	'local': -111px -68px 12px 16px,
	'lupa': -23px -111px 13px 13px,
	'next': 0px -134px 7px 9px,
	'prev': -17px -134px 7px 9px,
	'tel2': -133px -68px 10px 12px,
	'zap': -111px -42px 16px 16px,
);

@if $nativeCssIconVarialables {
	:root{
		@each $icon,$value in $icons {
			/*icon-#{$icon}*/
			--icon-#{$icon}-width: #{nth($value, 3)};
			--icon-#{$icon}-height: #{nth($value, 4)};
			--icon-#{$icon}-bg: #{nth($value, 1)} #{nth($value, 2)};
		}
	}
}

.icon{
	background-image: url('../images/sprites.png');
	background-repeat:no-repeat;
	background-size: 143px 143px;

	display: inline-block;
	vertical-align: middle;

}

.icon__mask{
	mask-image: url('../images/sprites.png');
	mask-repeat:no-repeat;
	mask-size: 143px 143px;

	display: inline-block;
	vertical-align: middle;
	background-color: currentColor;
}

@each $icon,$value in $icons {
	.#{$icon} {
		width: nth($value,3);
		height: nth($value,4);
		$sprite-position: #{nth($value,1)} #{nth($value,2)};

		&.icon{
			background-position: $sprite-position;
		}

		&.icon__mask{
			mask-position: $sprite-position;
		}
	}
}
