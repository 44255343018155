@import "_home.scss";

.interna__header{
	background: rgb(0,179,255);
	background: linear-gradient(0deg, rgba(0,179,255,1) 0%, rgba(0,119,182,1) 100%);
	color: #fff;
	padding: 32px 0px;

	@include media-breakpoint-down (md) {
		padding: 20px 0px;
	}

	.row{
		align-items: center;
	}

	.interna__header__tabs{
		display: flex;
		column-gap: 5px;
		font-family: poppinslight;
		font-size: 12px;
		line-height: 14px;
		align-items: center;
		margin-bottom: 5px;

		.barrinha{
			margin: 0px;
		}

		.active{
			font-family: poppinssemibold;
			text-decoration: underline;
		}
	}
}
.sub__interna__header{
	font-family: poppinssemibold;
	font-size: 25px;
	line-height: 29px;

	@include media-breakpoint-down (md) {
		margin-bottom: 10px;
		font-size: 20px;
		line-height: 24px;
	}
}
.titulo__interna__header{
	color: rgba(#fff, 0.3);
	font-size: 100px;
	line-height: 90px;
	font-family: bebas;
	-webkit-text-stroke: 1px #fff;
	text-align: right;

	@include media-breakpoint-down (md) {
		font-size: 50px;
		line-height: 50px;
	}
}
.interna__conteudo{
	padding: 70px 0px 100px 0px;

	@include media-breakpoint-down (md) {
		padding: 35px 0px 50px 0px;
	}
	&.quem{
		.row{
			align-items: center;

			.col-lg-6{
				margin-bottom: 50px;
			}
		}
	}
}
.missao__grid{
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 25px;

	@include media-breakpoint-down (md) {
		grid-template-columns: 1fr;
		row-gap: 15px;
	}

	.missao__card{
		padding: 20px 25px;
		border-radius: 25px;
		box-shadow: 0px 0px 15px rgba(#000, 0.4);
		background-color: #fff;
		display: flex;
		flex-direction: column;
		justify-content: center;

		.missao__card__icone{
			margin-bottom: 5px;
		}

		.missao__card__titulo{
			font-family: poppinsbold;
			font-size: 15px;
			line-height: 18px;
			color: #3e6c91;
			margin-bottom: 15px;
		}

		.missao__card__desc{
			font-size: 14px;
			line-height: 18px;
			font-family: poppinslight;
			color: #4d4d4d;
		}
	}
}
.interna__quem__img{
	text-align: center;
}
.titulo__interna__quem{
	font-family: poppinssemibold;
	font-size: 35px;
	line-height: 38px;
	color: #0084bc;
	margin-bottom: 25px;

	@include media-breakpoint-down (md) {
		font-size: 26px;
		line-height: 30px;
	}
}
.servico__card{
	display: flex;
	align-items:center;
	padding: 30px 35px;
	border: 1px solid #d3d3d3;
	column-gap: 30px;
	border-radius: 25px;
	transition: all 0.3s linear;
	margin-bottom: 24px;

	@include media-breakpoint-down (sm) {
		flex-direction: column;
		padding: 15px 10px;
		align-items: center;
		text-align: center;

		.servico__card__img{
			text-align: center;
			margin-bottom: 10px;
		}
	}

	.servico__card__img{
		min-width: 105px;
		background-color: #0084BC;
		max-width: 105px;
		border-radius: 50%;
		overflow: hidden;

		img{
			border-radius: 50%;
		}
	}
	.servico__card__titulo{
		font-family: poppinssemibold;
		font-size: 20px;
		line-height: 24px;
		color: #0187bd;
		margin-bottom: 5px;
	}
	.servico__card__desc{
		font-family: poppinslight;
		font-size: 14px;
		line-height: 18px;
		color: #282828;
		--linhas: 4;
		margin-bottom: 10px;
	}
	.servico__card__btn{
		font-family: poppinssemibold;
		font-size: 16px;
		line-height: 20px;
		color: #d3d3d3;
		text-decoration: underline;
		transition: all 0.3s linear;

		&:hover{
			color: #0187bd;
		}
	}

	&:hover{
		border-color: #0187bd;

	}
}
.serv__interna{
	.row{
		align-items: center;
		@include media-breakpoint-down (md) {
			.col-lg-6{
				margin-bottom: 40px;
			}
		}
	}
	.conteudo__gerenciavel{
		text-align: justify;
		margin-bottom: 30px;
	}
}
.servico__interna__sub{
	font-family: poppinssemibold;
	font-size: 30px;
	line-height: 34px;
	color: #0084bc;
	margin-bottom: 30px;

	@include media-breakpoint-down (sm) {
		font-size: 22px;
		line-height: 26px;
	}
}
.servico__interna__img{
	text-align: center;
}
.interna__titulo{
	font-family: poppinssemibold;
	font-size: 30px;
	line-height: 34px;
	color: #0187bd;
	margin-bottom: 25px;
}
.contato__interna__grid{
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	row-gap: 20px;
	column-gap: 20px;
	margin-bottom: 30px;

	.colspan{
		grid-column: span 2;
	}

	@include media-breakpoint-down (sm) {
		grid-template-columns: 1fr;

		.colspan{
			grid-column: unset;
		}
	}
}
.contato__item__mapa{
	@include media-breakpoint-down (md){
		margin-bottom: 15px;
	}
	.embed-responsive{
		padding-bottom: 25%;
		iframe{
			border-radius: 15px;
		}
	}
}
.contato__item__interna{
	display: flex;
	align-items:flex-start;
	column-gap: 10px;
	font-family: poppinslight;
	font-size: 13px;
	line-height: 16px;
	color: #373031;

	.contato__item__conteudo{
		padding-top: 5px;
	}

	.contato__item__icone{
		width: 40px;
		min-width: 40px;
	}
}
.contato__input{
	width: 100%;
	display: block;
	border: none;
	background-color: #f7f7f7;
	min-height: 45px;
	padding: 16px 18px;
	border-radius: 10px;
	font-family: poppinslight;
	font-size: 14px;
	line-height: 16px;
	resize: none;

	&:focus{
		outline: none;
	}
}
.v-select-output{
	width: 100%;
	display: block;
	border: none;
	background-color: #f7f7f7;
	min-height: 45px;
	padding: 16px 18px;
	border-radius: 10px;
	height: unset;
	font-family: poppinslight;
	font-size: 14px;
	line-height: 16px;

	&:focus{
		outline: none;
		box-shadow: unset;
		background-color: #f7f7f7;
	}

	&:before{
		margin-top: 4px;
	}
}
.contato__form{
	.form-group{
		margin-bottom: 8px;
	}
}
.contato__estado__grid{
	display: grid;
	grid-template-columns: 150px 1fr;
	column-gap: 8px;

	@include media-breakpoint-down (xs) {
		grid-template-columns: 1fr;
	}
}
.segura__botao{
	background-color: #f7f7f7;
	border-radius: 10px;
	padding-right: 8px;
	padding-bottom: 10px;
	display: flex;
	align-items:flex-end;
	justify-content: flex-end;
	margin-bottom: 5px;

	.form-group{
		width: 100%;
	}

	.enviar__btn{
		width: 35px;
		height: 35px;
		min-width: 35px;
		background-color: #24b1ed;
		color: #fff;
		border: none;
		border-radius: 50%;
	}
}
.checkbox__custom{

	a{
		text-decoration: underline;
		color: #0084bc;
	}

	.custom__check__input{
		position: fixed;
		left: -100vw;

		&:checked{
			& ~ .custom__check__label{
				&:after{
					opacity: 1;
				}
			}
		}
	}
	.custom__check__label{
		position: relative;
		padding-left: 22px;
		cursor: pointer;
		font-family: poppinsregular;
		font-size: 13px;
		line-height: 15px;

		&:before{
			content: '';
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0px;
			width: 16px;
			height: 16px;
			border-radius: 50%;
			border: 2px solid #0084bc;
			transition: all 0.3s linear;
		}

		&:after{
			content: '';
			position: absolute;
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background-color: #0084bc;
			top: 50%;
			left: 4px;
			transform: translateY(-50%);
			transition: all 0.3s linear;
			opacity: 0;
		}
	}
}
.segura__file{
	.v-file{
		height: 50px;

		.v-file-label{
			border: none;
			padding: 0px;
			border: none;
			display: flex;
			column-gap: 10px;
			align-items: center;

			.v-file-output{
				max-width: 240px;
				width: 100%;
				overflow: visible;
				white-space: inherit;
				font-family: poppinsregular;
				font-size: 13px;
				line-height: 16px;
			}
		}

		& > *{
			height: 50px;
		}
	}
}

.modal__custom,
.modal__portfolio{
	.modal-content{
		padding: 30px 0px 60px 0px;
		border-radius: 30px;
	}
	.modal-header{
		position: relative;
		padding: 0px;
		border: none;
		min-height: 45px;

		button{
			padding: 8px 20px;
			background-color: #24b1ed;
			color: #fff;
			border: none;
			font-family: poppinsbold;
			font-size: 15px;
			line-height: 18px;
			border-radius: 10px;
			position: absolute;
			top: 0px;
			right: -15px;
		}
	}
}
.modal__portfolio{
	.modal-dialog{
		max-width: 1110px;
	}
}
.port__carousel{
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 25px;

	@include media-breakpoint-down (md) {
		flex-direction: column;

		.carousel__left{
			margin-bottom: 15px;
		}
	}

	.carousel-item{
		position: relative;
		padding-top: 10px;

		.span__indict{
			position: absolute;
			top: 0px;
			left: 5px;
			background-color: #969696;
			color: #fff;
			font-family: poppinssemibold;
			font-size: 15px;
			line-height: 17px;
			padding: 6px 9px;
			border-radius: 5px;
		}
	}

	.carousel__left{
		width: 100%;
		max-width: 525px;
		overflow: hidden;
	}

	.carousel-indicators{
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		position: relative;
		top: unset;
		margin: unset;
		left: unset;
		transform: unset;
		text-indent: 0px;
		max-height: 580px;
		overflow-y: auto;

		@include media-breakpoint-down (md) {
			grid-template-columns: repeat(6, 1fr);
			max-height: unset;
			column-gap: 5px;
			row-gap: 5px;
			overflow: visible;
		}

		li{
			text-indent: 0px;
			width: 92px;
			max-width: 100%;
			height: 102px;
			padding-top: 10px;
			position: relative;
			background-color: transparent;
			opacity: 1;
			border: none;
			@include media-breakpoint-down (md) {
				width: 100%;
			}
			img{
				border: 3px solid #d3d3d3;
				border-radius: 0px 15px 15px 15px;
				transition:all 0.3s linear;
			}
			.span__indict{
				position: absolute;
				top: 0px;
				left: 5px;
				background-color: #969696;
				color: #fff;
				font-family: poppinssemibold;
				font-size: 15px;
				line-height: 17px;
				padding: 6px 9px;
				border-radius: 5px;
				transition:all 0.3s linear;
			}
			&.active{
				img{
					border: 3px solid #0187bd;
				}
				.span__indict{
					background-color: #0187bd;
				}
			}
		}
	}
}
.modal__port__titulo{
	font-family: poppinssemibold;
	font-size: 35px;
	line-height: 38px;
	color: #0084bc;
	text-align: center;
	margin-bottom: 20px;
}
.segura__carousel__nav{
	padding-top: 20px;
	display: flex;
	justify-content:center;
	column-gap: 10px;

	a{
		width: 55px;
		min-width: 55px;
		height:55px;
		border: 2px solid #0084bc;
		color: #0084bc;
		background-color: transparent;
		transition: all 0.3s linear;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		transition: all 0.3s linear;

		&:hover{
			background-color: #0084bc;
			color: #fff;
		}
	}
}
.btn__ordem{
	background-color: transparent;
	width: 100%;
	max-width: 260px;
	margin-left: auto;
	margin-right: 0px;
	color: #fff;
	font-family: poppinsbold;
	display: flex;
	justify-content: space-between;
	padding: 12px 17px;
	font-size: 14px;
	line-height: 16px;
	align-items: center;
	border-radius: 25px;
	border: none;
	background-color: #24b1ed;
	height: 50px;

	&:hover{
		color: #fff;
	}
}
.categoria__segura__radio{
	.categoria__radio{
		position: fixed;
		left: -100vw;

		&:checked{
			& ~ .categoria__label{

				&:before{
					border-color: #000;
					background-color: #fff;
				}
				&:after{
					opacity: 1;
				}
			}
		}
	}
	.categoria__label{
		padding-left: 20px;
		position: relative;
		font-family: 'poppinsregular', sans-serif;
		font-weight: 300;
		font-size: 14px;
		line-height: 18px;
		color: #000;
		transition: all 0.3s linear;
		cursor: pointer;

		&:hover{

			&:before{
				border-color: #000;
				background-color: #fff;
			}
		}

		&:before{
			content: '';
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0px;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			border: 1px solid #000;
			transition: all 0.3s linear;
		}

		&:after{
			content: '';
			position: absolute;
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background-color: #000;
			top: 50%;
			left: 3px;
			transform: translateY(-50%);
			transition: all 0.3s linear;
			opacity: 0;
		}
	}
}
.dropdown{
	&.filtro{
	max-width: 260px;
	margin-left: auto;
	margin-right: 0px;
	margin-bottom: 40px;
	background-color: #f6f6f6;
	border-radius: 0px 0px 30px 30px;
	width: 100%;
		&.show{
			.btn__filtro,
			.btn__ordem{
				position: relative;
				z-index: 1050;
				background: #0084bc;
				color: #fff;

				&:after{
					transform: rotate(180deg);
				}
			}
		}

		.dropdown-menu{
			z-index: 1000;
			border-radius: 0;
			box-shadow: 0px 6px 9px -3px rgba(0,0,0,0.75);
			-webkit-box-shadow: 0px 6px 9px -3px rgba(0,0,0,0.75);
			-moz-box-shadow: 0px 6px 9px -3px rgba(0,0,0,0.75);
			width: 100%;
			left: 0px !important;
			transform: none !important;
			top: calc(100% - 20px) !important;
			padding-top: 30px;
			border-radius: 0px 0px 15px 15px;

			.drop-content{
				display: flex;
				padding: 20px;

				.categoria__box{
					width: 100%;
				}

				@include media-breakpoint-down(md){
					flex-direction: column;
					align-items: flex-start;
				}
			}


		}
		.box__filtro__ordenacao{
			position: relative;
		}
	}
}
.btn__filtrar{
	width: 120px;
	height: 30px;
	background-color: #000;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 20px;
	color: #fff;
	font-family: poppinsbold;
	font-size: 14px;
	line-height: 14px;
	display: block;
}
.titulo__cassificar{
	font-family: poppinssemibold;
	font-size: 18px;
	line-height: 22px;
	color: #0084bc;
	padding-top: 12px;
	margin-bottom: 50px;

	@include media-breakpoint-down (sm) {
		margin-bottom: 15px;
		padding-top: 0px;
	}
}
.classificar__sub{
	font-family: poppinsbold;
	font-size: 15px;
	line-height: 18px;
	color: #666666;
	margin-bottom: 10px;
}
.classificar__checks{
	margin-bottom: 22px;
	.custom__check__label{
		color: #666666;

		&:before{
			border-color: #666666;
		}
		&:after{
			background-color: #666666;
		}
	}
}
.produtos__grid{
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 30px;
	row-gap: 40px;

	@include media-breakpoint-down (sm) {
		grid-template-columns: repeat(2, 1fr);
		column-gap: 20px;
		row-gap: 20px;
	}
	@include media-breakpoint-down (xs) {
		grid-template-columns: 1fr;
		column-gap: 20px;
		row-gap: 20px;
	}
}
.paginacao{
	display: flex;
	justify-content: center;
    flex-wrap: wrap;
    border-radius: none;
    border: none;
    list-style: none;
    padding-left: 0px;
	padding-top: 55px;

	.paginacao__item{
		a{
            font-family: 'poppinsbold';
            font-size: 15px;
            line-height: 15px;
            color: #d5d5d5;
            display: flex;
            width: 35px;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            height: 35px;
            position: relative;
            transition: all 0.3s linear;
			border: none;
			background-color: transparent;
			border: 1px solid #d5d5d5;
            margin: 0px 2px;
            padding: 0px;
            &:focus{
                box-shadow: none;
            }
        }

        &.active{
           a{
				color: #fff;
				background-color: #0084bc;
                text-decoration: none;
				pointer-events: none;
				border-color: #0084bc;

				&:hover{
					background-color: #0084bc;
				}
           }
        }

		&:hover{
			a{
                text-decoration: none;
				background-color: darken( #fff, 10%);
				color: #666666;
            }
		}
	}
}
.row__produto__interna{
	@include media-breakpoint-down (md) {
		.col-lg-6{
			margin-bottom: 25px;
		}
	}
	.v__zoom__galery.owl-carousel{
		padding-top: 20px;
		a{
			display: block;
			position: relative;
			transition: all 0.3s linear;
			box-shadow: 0px 4px 8px rgba(#000, 0.2);

			img{
				border-radius: 0px;
			}

			&.active{
				&:after{
					opacity: 1;
				}
			}


			&:after{
				width: 100%;
				height: 100%;
				content: '';
				position: absolute;
				z-index: 2;
				top: 0px;
				left: 0px;
				background-color: transparent;
				border: 2px solid #24b1ed;
				opacity: 0;
				transition: all 0.3s linear;
				border-radius: 0px;
				overflow: hidden;
			}
		}
		.owl-nav{
			display: flex;
			justify-content:  center;
			padding-top: 20px;
			column-gap: 10px;

			&.disabled{
				display: none;
			}

			button{
				border: 2px solid #0084bc;
				color: #0084bc;
				border-radius: 50%;
				background-color: #fff;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: transparent;
				transition: all 0.3s linear;
				width: 55px;
				height: 55px;

				&:hover{
					background-color: #0084bc;
					color: #fff;
				}
			}
		}
	}
}
.row__miniaturas{
	.col__auto{
		margin-bottom: 10px;
		margin-right: 10px;
		margin-left: 10px;

		a{
			display: block;
			position: relative;
            transition: all 0.3s linear;
            box-shadow: 0px 4px 8px rgba(#000, 0.2);


			&:after{
				width: 100%;
				height: 100%;
				content: '';
				position: absolute;
				z-index: 2;
				top: 0px;
                left: 0px;
                background-color: #000;
				opacity: 0;
                transition: all 0.3s linear;
			}
		}

		.zoomGalleryActive{
			&:after{
				opacity: 0.6;
			}
		}

		&:first-child{
			margin-left: 0px;
		}
	}
}
.segura__sociais__produto{
	text-align: right;
	font-family: poppinslight;
	font-size: 12px;
	line-height: 15px;
	margin-bottom: 10px;
	padding-top: 20px;
}
.sociais__produto__span{
	margin-bottom: 5px;
}
.sociais__produto{
	display: flex;
	align-items: center;
	justify-content: flex-end;
	column-gap: 5px;

	a{
		border: 1px solid #d3d3d3;
		color: #d3d3d3;
		width: 30px;
		height: 30px;
		min-width: 30px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content:center;
		transition: all 0.3s linear;

		&:hover{
			border-color: #24b1ed;
			color: #fff;
			background-color: #24b1ed;
			text-decoration: none;
		}
	}
}
.tab__produto{
	padding-top: 40px;
	border-top: 5px solid #ededed;
	margin-top: 20px;

	.nav-tabs{
        border-bottom: none;
        flex-wrap: wrap;
	}
	.nav-link{
		border: none;
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 50px;
        padding: 8px 15px;
		background-color: #dbdbdb;
		color: #666666;
		font-size: 15px;
		line-height: 17px;
		transition: all 0.3s linear;
		font-family: 'poppinssemibold';
		letter-spacing: 0px;
        margin: 0px;
        text-align: center;
        border-radius: 0px;

		&.active{
			background-color: #ededed;
            color: #666666;
            font-family: 'poppinssemibold';
		}
	}
	.nav-item{
		&:first-child{
			.nav-link{
				border-radius: 0px;
			}
		}
		&:last-child{
			.nav-link{
				border-radius: 0px;
			}
		}
	}
	.tab-content{
		padding: 50px 25px 35px 25px;
        background-color: #ededed;
        color: #000;
		border-radius: 0px;
		font-size: 14px;
		line-height: 18px;
		font-family: poppinslight;
	}
}
.produto__tab__titulo{
	font-family: poppinsbold;
	font-size: 20px;
	line-height: 24px;
	color: #666666;
	margin-bottom: 22px;
}
.btns__produtos{
	display: flex;
	justify-content:center;
	column-gap: 10px;
	flex-wrap: wrap;
	row-gap: 10px;
	padding-top: 20px;
}
.produto__detalhe__titulo{
	font-family: poppinssemibold;
	font-size: 30px;
	line-height: 34px;
	color: #0084bc;
	margin-bottom: 15px;
}
.produto__detalhe__codigo{
	font-family: poppinslight;
	font-size: 12px;
	line-height: 15px;
	color: #666666;
	margin-bottom: 10px;
}
